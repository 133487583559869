<template>
	<div class="cumen">....</div>
</template>

<script>
export default {
	name: "Cumen",
	beforeCreate() {
    // 跳转到小程序的断链接页面
    window.location.href = 'weixin://dl/business/?t=is5jm9phQ4f';
	}
};
</script>

<style lang="scss" scoped>
 p {
	 font-size: 20px;
	 text-align: center;
 }
</style>
